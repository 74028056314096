export default {
  id: '9499f8f90b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="home">\n     \n  <!-- Top Navbar-->\n  <div class="navbar"> \n    <div class="navbar-bg"> </div>\n      <div class="navbar-inner"> \n        <div class="left"></div> \n        <div class="title sliding" style="text-align:center">yogafürdich Streaming</div> \n        <div class="right"></div> \n       \n      </div>\n    </div>	\n  \n    <!-- Toolbar Bottom-->\n    <div class="toolbar toolbar-bottom">\n      <div class="toolbar-inner">\n        <div class="left">V1.0</div> \n        <div class="right">06-2020</div> \n      </div>\n    </div>\n    <!-- Scrollable page content-->\n    <div class="page-content">\n\n      <div class="block">\n      <img class="yfd-logo" src="static/images/web-icon-yfd.png">\n    </div>\n\n      <div class="tilescontainer">\n        <div class="checkliste"><a href="/schritt01/"><div class="tilescontent"><i class="f7-icons">checkmark</i><br>Checkliste</div></a></div>\n        <div class="faq"><a href="/faq/"><div class="tilescontent"><i class="f7-icons">question</i><br>FAQ</div></a></div>\n        <div class="support"><a href="/support/"><div class="tilescontent"><i class="f7-icons">phone</i><br>Support</div></a></div>\n      </div>\n    </div>\n      \n  </div>\n  </div>\n\n';
      return r;
    }(this);
  },

  styleScoped: false
};