
import HomePage from '../pages/home.f7.html';
import Schritt01Page from '../pages/schritt01.f7.html';
import Schritt02Page from '../pages/schritt02.f7.html';
import Schritt03Page from '../pages/schritt03.f7.html';
import Schritt04Page from '../pages/schritt04.f7.html';
import Schritt05Page from '../pages/schritt05.f7.html';
import Schritt06Page from '../pages/schritt06.f7.html';
import Schritt07Page from '../pages/schritt07.f7.html';
import Schritt08Page from '../pages/schritt08.f7.html';
import Schritt09Page from '../pages/schritt09.f7.html';
import Schritt10Page from '../pages/schritt10.f7.html';
import FAQPage from '../pages/faq.f7.html';
import SupportPage from '../pages/support.f7.html';


import DynamicRoutePage from '../pages/dynamic-route.f7.html';
import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/schritt01/',
    component: Schritt01Page,
  },
  {
    path: '/schritt02/',
    component: Schritt02Page,
  },
  {
    path: '/schritt03/',
    component: Schritt03Page,
  },
  {
    path: '/schritt04/',
    component: Schritt04Page,
  },
  {
    path: '/schritt05/',
    component: Schritt05Page,
  },
  {
    path: '/schritt06/',
    component: Schritt06Page,
  },
  {
    path: '/schritt07/',
    component: Schritt07Page,
  },
  {
    path: '/schritt08/',
    component: Schritt08Page,
  },
  {
    path: '/schritt09/',
    component: Schritt09Page,
  },
  {
    path: '/schritt10/',
    component: Schritt10Page,
  },
  {
    path: '/faq/',
    component: FAQPage,
  },
  {
  path: '/support/',
  component: SupportPage,
  },

  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;