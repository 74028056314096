export default {
  id: '488fe39544',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page" data-name="schritt03">\n       \n    <!-- Top Navbar-->\n    <div class="navbar"> \n      <div class="navbar-bg"> </div>\n        <div class="navbar-inner"> \n          <div class="left"> <a href="#" class="link back"><i class="icon icon-back"></i></a></div> \n          <div class="title sliding" style="text-align:center">Checkliste</div> \n          <div class="right"></div> \n         \n        </div>\n      </div>	\n    \n      <!-- Toolbar Bottom-->\n      <div class="toolbar toolbar-bottom">\n        <div class="toolbar-inner">\n          <a href="/schritt02/" class="link back">Schritt zurück</a>\n          <a href="/schritt04/" class="link">nächster Schritt</a>\n        </div>\n      </div>\n      <!-- Scrollable page content-->\n      <div class="page-content">\n  \n        <div class="block-title yfd-title">Schritt 3</div>\n        <div class="block yfd-block">\n            <!-- block content -->\n            <h3 class="yfd-h3">Headset aufsetzen</h3>\n\n            <p>Das Headset von hinten aufsetzen.</p> \n            <p>Stelle den Mikrofonbügel evt. vorsichtig so ein, dass die Schaumstoffkapsel  neben dem Gesicht ist, damit du nicht hinein atmest.</p> \n            <p>Kabel und Sender hinten unter deinem Shirt durchfädeln und Sender seitlich an deiner Hose befestigen.</p> \n            <p>Achte darauf, dass der schwarze Adapter zwischen Sender und hautfarbenem Headset-Kabel nicht abgeknickt ist.</p>           \n            \n            <img src="static/images/pwa-rode-headset.jpg" style="width:90%">\n\n        </div>\n  \n  \n      </div>\n        \n    </div>\n    </div>\n  \n  ';
      return r;
    }(this);
  },

  styleScoped: false
};